import React from "react"
import { Button, Modal, Nav, Navbar } from "react-bootstrap"
import { FieldErrors, useFormContext } from "react-hook-form"
import { useLocation } from "react-router"
import useCustomErrorMarkerTry from "../../../hooks/patient/customErrorMarker"
import { PatientSchemaType, usePatientEditMutation } from "../../../services/CelebPatient.Service"
import { getPatientTreatmentFilesQuery } from "../../../services/CelebTreatmentFiles.Service"
import { usePatientDialogStore } from "../../../store/patientDailogStore"
import { useTeethStore } from "../../../store/toothSelectionStore"
import { userSessionStore } from "../../../store/userSessionStore"
import { TreatmentFileCategory } from "../../../utils/appConstants"
import { clearHash } from "../../utlity/GenricFunctions"
import ProgressBarLoader from "../../utlity/ProgressBar.Loader"
import { uploadMedicalFiles } from "../patient-v2/PatientNavbar"
import "./Patient.css"
import { fileResult } from "./PatientPhotos.tab"
import { photosLibraryReset } from "./defaultFileShowCase"
const PatientNavbar = () => {
	const { setValue, getValues, control } = useFormContext<PatientSchemaType>()
	const {
		cbctDocumentJson,
		individualPhoto,
		xRays,
		scan,
		patientCompositePhotoJson,
		navErrors,
		typesEditOpen,
		clearState,
		setErrorToShow,
		setTypesEditOpen,
		setCustomError,
	} = usePatientDialogStore()
	const { mutateAsync: editPatient } = usePatientEditMutation()
	const { teethBonding, teethExtraction, resetTeethSelection } = useTeethStore()
	const { setProgressLoaderObj, setProgressLoaderJson } = userSessionStore()
	const { data: defaultTreatmentFiles } = getPatientTreatmentFilesQuery( typesEditOpen )
	const { customErrorMarker } = useCustomErrorMarkerTry()
	const locationHash = useLocation()
	const clearHashClear = clearHash()

	const additionalApiRequirments = async () => {
		setProgressLoaderObj( "max", 100 )
		setProgressLoaderObj( "now", 0 )
		const date = Date.now()
		const doctorAddress = getValues( "selectedOffice" )
		setValue( "office", doctorAddress )
		setValue( "treatmentPlan.teethBonding", teethBonding )
		setValue( "treatmentPlan.teethExtraction", teethExtraction )
		setValue( "treatmentPlan.treatmentStart", date )
		setValue( "treatmentPlan.treatmentPlanDoctorAddressId", doctorAddress )
		setValue( "treatmentPlan.upperBracketSlotSize", "0.018" )
		setValue( "treatmentPlan.lowerBracketSlotSize", "0.018" )
		control.handleSubmit( checkSecondaryFilesValidation, onError )()
	}

	const uploadDocumentsToServer = async () => {
		setProgressLoaderObj( "show", true )
		setProgressLoaderObj( "now", 10 )
		const uploadCbctData = await uploadFiles( cbctDocumentJson, TreatmentFileCategory.PATIENT_CBCT )
		setProgressLoaderObj( "now", 20 )

		const uploadPhotoDataComposite = await uploadFiles(
			patientCompositePhotoJson,
			TreatmentFileCategory.PATIENT_PHOTO,
		)
		const uploadPhotoDataJson = await uploadFiles(
			individualPhoto,
			TreatmentFileCategory.PATIENT_PHOTO,
		)
		setProgressLoaderObj( "now", 30 )
		const uploadXRaysData = await uploadFiles( xRays, TreatmentFileCategory.PATIENT_XRAY )
		setProgressLoaderObj( "now", 40 )
		const uploadScanData = await uploadFiles( scan, TreatmentFileCategory.PATIENT_SCAN )
		setProgressLoaderObj( "now", 50 )
		const treatmentFiles = [
			...uploadCbctData,
			...uploadPhotoDataComposite,
			...uploadXRaysData,
			...uploadScanData,
			...uploadPhotoDataJson,
		].filter( ( file ): file is UploadData =>
			file !== undefined && file.uri.length >= 1 ? true : false,
		)
		setProgressLoaderObj( "now", 80 )
		setValue( "treatmentPlan.treatmentFiles", treatmentFiles )
		control.handleSubmit( submit, onError )()
	}

	const uploadFiles = async ( files: { [key: string]: File | null }, category: string ) => {
		const uploadPromises = Object.keys( files ).map( async ( fileKey ) => {
			const file = files[ fileKey ]
			const tempObj = {
				category: "",
				subCategory: "",
				originalFileName: "",
				uri: "",
			}
			if ( file ) {
				return await uploadMedicalFiles( file, category, fileKey )
			} else {
				defaultTreatmentFiles.forEach( ( fileData: fileResult ) => {
					if ( fileData.fileSubCategory == fileKey ) {
						tempObj.category = fileData.fileCategory
						tempObj.subCategory = fileData.fileSubCategory
						tempObj.originalFileName = fileData.originalFileName
						tempObj.uri = fileData.fileUri
					}
				} )
				return tempObj
			}
		} )
		return await Promise.all( uploadPromises )
	}

	const checkSecondaryFilesValidation = ( value: PatientSchemaType ) => {
		console.log( customErrorMarker() )
		if ( customErrorMarker() ) return null
		uploadDocumentsToServer()
	}

	const submit = ( value: PatientSchemaType ) => {
		editPatient( { id: typesEditOpen, payload: value } )
			.then( () => {
				setProgressLoaderJson( {
					max: 100,
					message: "Data Uploaded Successfully",
					now: 100,
					show: true,
				} )
				setProgressLoaderObj( "show", false )
				clearFormData()
			} )
			.catch( ( error ) => {
				return console.log( "error", error )
			} )
	}

	const onError = ( errorData: FieldErrors<PatientSchemaType> ) => {
		console.log( errorData )
		customErrorMarker( errorData )
	}

	const clearFormData = () => {
		resetTeethSelection()
		clearState()
		setTypesEditOpen( null )
		photosLibraryReset()
		clearHashClear()
		setErrorToShow( "" )
	}
	return (
		<React.Fragment>
			<Navbar id="navbarScroll" as={Modal.Header}>
				<Nav variant="tabs" defaultActiveKey="#PatientInfo" className="d-flex mt-2 me-auto">
					<Nav.Link
						href="#PatientInfo"
						active={locationHash.hash == "#PatientInfo"}
						className={navErrors.infoTab}
					>
						Patient Edit Info
					</Nav.Link>
					<Nav.Link
						href="#Scan"
						active={locationHash.hash == "#Scan"}
						className={navErrors.scanTab}
					>
						Scan
					</Nav.Link>
					<Nav.Link
						href="#Instructions"
						active={locationHash.hash == "#Instructions"}
						className={navErrors.instructions}
					>
						Instructions
					</Nav.Link>
					<Nav.Link
						href="#Photos"
						active={locationHash.hash == "#Photos"}
						className={navErrors.photos}
					>
						Photos
					</Nav.Link>
					<Nav.Link href="#X-rays" active={locationHash.hash == "#X-rays"}>
						X-rays
					</Nav.Link>
					<Nav.Link href="#CBCT" active={locationHash.hash == "#CBCT"}>
						CBCT
					</Nav.Link>
				</Nav>
				<span>
					<Button onClick={additionalApiRequirments} className="mx-2">
						Edit Patient
					</Button>
					<Button variant="secondary" onClick={clearFormData}>
						Cancel
					</Button>
				</span>
			</Navbar>
			<ProgressBarLoader />
		</React.Fragment>
	)
}

export type UploadData = {
	category: string
	subCategory: string
	originalFileName: string
	uri: string
}

const customValidationFunction = (
	cbctDocumentJson: { [key: string]: File | null },
	individualPhoto: { [key: string]: File | null },
	xRays: { [key: string]: File | null },
	scan: { [key: string]: File | null },
	patientCompositePhotoJson: { [key: string]: File | null },
) => {
	const checkIndividualPhotos = Object.values( individualPhoto ).filter( Boolean )
	const checkCbctDocumentJson = Object.values( cbctDocumentJson ).filter( Boolean )
	const checkXRays = Object.values( xRays ).filter( Boolean )
	const checkScan = Object.values( scan ).filter( Boolean )
	const checkPatientCompositePhotoJson = Object.values( patientCompositePhotoJson ).filter( Boolean )

	return {
		checkIndividualPhotos: checkIndividualPhotos,
		checkCbctDocumentJson: checkCbctDocumentJson,
		checkXRays: checkXRays,
		checkScan: checkScan,
		checkPatientCompositePhotoJson: checkPatientCompositePhotoJson,
	}
}

export default PatientNavbar
