const Financials = () => {
	return (
		<div
			style={{
				width: "-webkit-fill-available",
				backgroundColor: "#d9d9d9",
				borderRadius: "10px",
				display: "flex",
				alignItems: "center",
				justifyContent: "center",
			}}
		>
			Comming Soon
		</div>
	)
}

export default Financials
