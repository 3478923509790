import "../../assets/styles/components/buttons/celebButtonToothNumber.css"
import { toothCondition } from "../../store/toothSelectionStore"

export interface CelebButtonTootheNumberProps {
	tootheNumber: number
	active?: toothCondition | boolean
	disabled?: toothCondition | boolean
}

const CelebButtonTootheNumber = ( props: CelebButtonTootheNumberProps ) => {
	return (
		<div
			className={
				"button-tooth-number " +
				( props.active == "disabled" ? "disabled" : props.active == "selected" ? "active " : "" )
			}
		>
			{props.tootheNumber}
		</div>
	)
}

export default CelebButtonTootheNumber
