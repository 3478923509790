import { faPencil, faTrash } from "@fortawesome/free-solid-svg-icons"
import { zodResolver } from "@hookform/resolvers/zod"
import React from "react"
import { Button, Form, Modal, Nav, Navbar, Row, Spinner } from "react-bootstrap"
import { FieldErrors, FormProvider, useForm } from "react-hook-form"
import CreatableSelect from "react-select/creatable"
import LoadingOverlay from "../../../assets/styles/components/loader/loadingOverlay"
import { constants } from "../../../services/CelebApiConstants"
import { CelebMedicalFileService } from "../../../services/CelebMedicalFile.Service"
import { ServiceSchemaForm, useCreateServiceMutation } from "../../../services/CelebService.Service"
import { useServiceDialogStore } from "../../../store/serviceDialogStore"
import { TaskCategory } from "../../../utils/appConstants"
import { EditTrash } from "../../utlity/EditTrash"
import { CreatableSelectEdit, ServiceFormInput, ServiceFormType } from "./ServiceFormFields"
import ServiceTaskBox from "./ServiceTaskForm"

const ServicesAddDialog = () => {
	const methods = useForm<ServiceFormType>( {
		resolver: zodResolver( ServiceSchemaForm ),
		mode: "all",
	} )
	const serviceStore = useServiceDialogStore()
	const errors = methods.formState.errors
	const { mutateAsync: creatServiceAsync } = useCreateServiceMutation()
	const [ logo, setLogo ] = React.useState<FileList | null>()
	const getCategory: { [key: string]: ReactSelectCreatableCustom[] } = {
		provide: serviceStore.serviceProviderCategories,
		consume: serviceStore.serviceConsumerCategories,
	}
	const createCategory: { [key: string]: serviceStoreCreateCallback } = {
		provide: serviceStore.setServiceProviderCategories,
		consume: serviceStore.setServiceConsumerCategories,
	}

	const updateCategory: { [key: string]: serviceStoreCallback } = {
		provide: serviceStore.updateserviceProviderCategories,
		consume: serviceStore.updateserviceConsumerCategories,
	}
	const [ editValue, setEditValue ] = React.useState<ReactSelectCreatableCustom>( {
		value: "",
		label: "",
		type: "",
		show: "",
	} )

	const saveService = async ( value: ServiceFormType ) => {
		const logoUrl = logo != null ? await uploadLogo( logo ) : ""
		const formDataObj = {
			...value,
			categories: [
				...serviceStore.serviceConsumerCategories,
				...serviceStore.serviceProviderCategories,
			],
			logo: logoUrl,
		}
		creatServiceAsync( formDataObj ).then( () => closeService() )
	}

	const createOption = (
		labelName: string,
		categoryFor: TaskCategory.PROVIDER | TaskCategory.OTHERS,
	) => {
		const id = crypto.randomUUID()
		const dataToCheck = getCategory[ categoryFor ].filter(
			( x: ReactSelectCreatableCustom ) => x.label === labelName,
		)

		if ( dataToCheck.length >= 1 ) return
		createCategory[ categoryFor ]( {
			value: id,
			type: categoryFor,
			label: labelName,
		} )
	}

	const categoryToDelete = () => {
		if ( getCategory[ editValue.type ] == undefined ) return null
		const getTaskCategoryToDelete: { [key: string]: "consumerCategoryId" | "providerCategoryId" } =
			{
				provide: "providerCategoryId",
				consume: "consumerCategoryId",
			}
		const category = getTaskCategoryToDelete[ editValue.type ]
		const deleteClearance = methods
			.getValues( "tasks" )
			.filter( ( x ) => x[ category ] === editValue.value )

		if ( deleteClearance.length >= 1 )
			return methods.setError( "tasks", {
				message: "Please update the task first, task is dependant on the this category",
			} )

		const dataToDelete = getCategory[ editValue.type ].filter(
			( x: ReactSelectCreatableCustom ) => x.value != editValue.value,
		)

		updateCategory[ editValue.type ]( dataToDelete )
		setEditValue( { ...editValue, value: "", label: "", type: "" } )
	}

	const categoryToEdit = () => {
		const dataToEdit = getCategory[ editValue.type ].filter(
			( x: ReactSelectCreatableCustom ) => x.value != editValue.value,
		)
		updateCategory[ editValue.type ]( [ ...dataToEdit, editValue ] )
		setEditValue( { value: "", label: "", type: "" } )
	}

	const onSubmitError = ( errors2: FieldErrors<ServiceFormType> ) => {
		console.error( { errors2 } )
	}
	const closeService = () => {
		methods.reset()
		serviceStore.setCreateOpen( false )
		serviceStore.resetTaskCategories()
	}

	const editCallback = () => {
		editValue.value.length >= 2 ? setEditValue( { ...editValue, show: editValue.type } ) : null
	}

	return (
		<React.Suspense fallback={<LoadingOverlay />}>
			<Modal show={serviceStore.createOpen} onHide={closeService} size="xl" backdrop="static">
				<Navbar as={Modal.Header} closeButton>
					<Nav variant="tabs">
						<Nav.Link active className="mt-1">
							Create Service
						</Nav.Link>
					</Nav>
				</Navbar>
				<Modal.Body>
					<FormProvider {...methods}>
						<Form onSubmit={methods.handleSubmit( saveService, onSubmitError )}>
							<span className="d-inline-flex justify-content-evenly w-100">
								<ServiceFormInput label="Service Name" name="name" />
								<div>
									<Form.Label>Service Logo</Form.Label>
									<div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
										<Form.Control
											id="logo-upload"
											type="file"
											accept="image/*"
											onChange={( e: React.ChangeEvent<HTMLInputElement> ) => {
												setLogo( e.currentTarget?.files )
											}}
											hidden
										/>
										<label htmlFor="logo-upload">
											<img
												src={
													logo && logo[ 0 ]
														? URL.createObjectURL( logo[ 0 ] )
														: constants.IMAGEURL + "building.png"
												}
												style={{
													width: "2.5rem",
													height: "2.5rem",
													cursor: "pointer",
													borderRadius: "5px",
												}}
												alt="Selected Logo"
												onError={( { currentTarget } ) => {
													currentTarget.onerror = null
													currentTarget.src = constants.IMAGEURL + "building.png"
												}}
											/>
										</label>
									</div>
								</div>
								<div>
									<Form.Label>Service Status</Form.Label>
									<Form.Check
										type="checkbox"
										{...methods.register( "status" )}
										label={"Make Active"}
									/>
								</div>
								<div className="d-inline-flex align-items-center">
									<Button type="submit" onClick={methods.handleSubmit( saveService, onSubmitError )}>
										{methods.formState.isSubmitting ? (
											<>
												<Spinner animation="border" role="status" size="sm" /> Saving..{" "}
											</>
										) : (
											"Save"
										)}
									</Button>
									<Button variant="secondary" className="mx-2" onClick={closeService}>
										Cancel
									</Button>
								</div>
							</span>
							<hr />
							<section style={{ width: "100%", display: "flex" }}>
								<div style={{ width: "50%" }} className="mx-4">
									<span>
										<Form.Label>Manage Provider Categories</Form.Label>
										<span className="d-flex" style={{ width: "100%" }}>
											{editValue.show === TaskCategory.PROVIDER ? (
												<div style={{ width: "100%" }}>
													<CreatableSelectEdit
														value={editValue.label}
														onChange={( e ) => {
															setEditValue( { ...editValue, label: e.currentTarget.value } )
														}}
														autoFocus
														editCallback={categoryToEdit}
														closeCallback={() => setEditValue( { value: "", label: "", type: "" } )}
													/>
												</div>
											) : (
												<section style={{ width: "100%" }} className="d-flex">
													<div style={{ width: "100%" }}>
														<CreatableSelect
															isClearable
															options={serviceStore.serviceProviderCategories}
															onCreateOption={( e ) => createOption( e, TaskCategory.PROVIDER )}
															value={
																editValue.type == TaskCategory.PROVIDER
																	? editValue
																	: { value: "", label: "", type: "" }
															}
															onChange={( e ) => {
																setEditValue( {
																	value: e?.value + "",
																	label: e?.label + "",
																	type: e?.type + "",
																} )
															}}
														/>
													</div>
													<EditTrash
														editIcon={{
															color:
																editValue.type === TaskCategory.PROVIDER ? "#8faadc" : "#d9d4d4",
															icon: faPencil,
															style: { fontSize: "1.5rem" },
														}}
														trashIcon={{
															color:
																editValue.type === TaskCategory.PROVIDER ? "#5c636a" : "#d9d4d4",
															icon: faTrash,
															style: { fontSize: "1.5rem" },
														}}
														editCallback={editCallback}
														deleteCallBack={categoryToDelete}
													/>
												</section>
											)}
										</span>
									</span>
								</div>
								<span className="vr"></span>
								<div style={{ width: "50%" }} className="mx-4">
									<span>
										<Form.Label>Manage Consumer Categories</Form.Label>
										<span className="d-flex" style={{ width: "100%" }}>
											{editValue.show === TaskCategory.OTHERS ? (
												<div style={{ width: "100%" }}>
													<CreatableSelectEdit
														value={editValue.label}
														onChange={( e ) => {
															setEditValue( { ...editValue, label: e.currentTarget.value } )
														}}
														autoFocus
														editCallback={categoryToEdit}
														closeCallback={() => setEditValue( { value: "", label: "", type: "" } )}
													/>
												</div>
											) : (
												<section style={{ width: "100%" }} className="d-flex">
													<div style={{ width: "100%" }}>
														<CreatableSelect
															isClearable
															options={serviceStore.serviceConsumerCategories}
															onCreateOption={( e ) => createOption( e, TaskCategory.OTHERS )}
															value={
																editValue.type == TaskCategory.OTHERS
																	? editValue
																	: { value: "", label: "", type: "" }
															}
															onChange={( e ) => {
																setEditValue( {
																	value: e?.value + "",
																	label: e?.label + "",
																	type: e?.type + "",
																} )
															}}
														/>
													</div>
													<EditTrash
														editIcon={{
															color: editValue.type === TaskCategory.OTHERS ? "#8faadc" : "#d9d4d4",
															icon: faPencil,
															style: { fontSize: "1.5rem" },
														}}
														trashIcon={{
															color: editValue.type === TaskCategory.OTHERS ? "#5c636a" : "#d9d4d4",
															icon: faTrash,
															style: { fontSize: "1.5rem" },
														}}
														editCallback={editCallback}
														deleteCallBack={categoryToDelete}
													/>
												</section>
											)}
										</span>
									</span>
								</div>
							</section>
						</Form>
						<Row></Row>
						<hr />
						<p className="text-danger">{errors.tasks?.message}</p>

						<ServiceTaskBox />
					</FormProvider>
				</Modal.Body>
			</Modal>
		</React.Suspense>
	)
}

export const uploadLogo = async ( serviceLogo: FileList ) => {
	if ( serviceLogo.length >= 1 ) {
		const medicalFileService = CelebMedicalFileService.getInstance()
		const fileUploadResponse = await medicalFileService.getPublicUploadUrls( [ serviceLogo[ 0 ]?.name ] )
		const uploadUrl = fileUploadResponse.data.data[ 0 ].uploadUrl
		const response = await medicalFileService.uploadFileToS3( uploadUrl, serviceLogo[ 0 ] )
		if ( response.status === 200 ) {
			const logo = uploadUrl.split( "?" )[ 0 ].split( "/" )[ 3 ]
			return logo
		}
	}
}
export default ServicesAddDialog

export type PreviousRollBackType = {
	previousTasks: {}[]
	rollBackToTask: {}[]
}

export type ReactSelectCreatableCustom = {
	value: string
	label: string
	type: string
	show?: TaskCategory.PROVIDER | TaskCategory.OTHERS | string
}

type serviceStoreCreateCallback = ( next: ReactSelectCreatableCustom ) => void
type serviceStoreCallback = ( next: ReactSelectCreatableCustom[] ) => void
