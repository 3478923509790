import { faCaretDown, faFileArrowDown } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon as FA } from "@fortawesome/react-fontawesome"
import "@xyflow/react/dist/style.css"
import { useState } from "react"
import { Collapse } from "react-bootstrap"
import Moment from "react-moment"
import { useSearchParams } from "react-router-dom"
import { useFetchTimeline, useFetchTimelineTodo } from "../../services/celebApitTimeline"
import { downloadFile } from "../../utils/utilFunctions"
import "./graph.css"

const AppGraph = () => {
	const [ queryParam, setQueryParam ] = useSearchParams()
	const treatmentid = queryParam.get( "PatientId" )
	const { data: dataTimeline } = useFetchTimeline( treatmentid ? treatmentid : "" )
	const { data: dataTimelineTodo } = useFetchTimelineTodo( treatmentid ? treatmentid : "" )
	const [ showCollapsable, setCollapsable ] = useState<string>( "" )
	return (
		<div
			style={{ backgroundColor: "#d9d9d9", width: "100%", padding: "1rem", borderRadius: "11px" }}
		>
			<div className="timeline" style={{ width: "100%", overflow: "auto", height: "100%" }}>
				{dataTimeline.map( ( x ) => {
					return (
						<div className={`tl-item ${x.id ? "active" : ""}`}>
							<div className={`tl-dot ${x.taskId ? " b-warning" : "b-danger"}`}></div>
							<span className="w-100">
								<div className="d-flex justify-content-between w-100">
									<header>
										{x.timelineServiceTask.name.charAt( 0 ).toUpperCase() +
											x.timelineServiceTask.name.slice( 1 )}
										<br />
										<p style={{ color: "#818181" }}>
											{x.timelineActionedBy.firstName + " " + x.timelineActionedBy.lastName}
											<FA
												icon={faCaretDown}
												size="xs"
												className="mx-2"
												color="#8faadc"
												onClick={() => setCollapsable( ( current ) => ( current == x.id ? "" : x.id ) )}
											/>
											<br />
											<b>{x.action == "task_completed" ? "" : x.action}</b>
										</p>
									</header>
									<header style={{ color: "#818181" }}>
										<Moment format="MM/DD/YYYY hh:mma" local>
											{x.createdAt}
										</Moment>
									</header>
								</div>

								<Collapse in={showCollapsable == x.id}>
									<div id="d-flex">
										<p
											className="text-muted"
											hidden={
												x.timelineTreatmentPlanServiceTask.note ||
												x.timelineTreatmentPlanServiceTask.uploadFileUri
													? true
													: false
											}
										>
											No comment or File
										</p>
										<span hidden={!x.timelineTreatmentPlanServiceTask.note}>
											{x.timelineTreatmentPlanServiceTask.note}
										</span>
										<span
											style={{ float: "right" }}
											hidden={!x.timelineTreatmentPlanServiceTask.uploadFileUri}
										>
											File Download
											<FA
												icon={faFileArrowDown}
												size="xl"
												className="mx-2"
												color="#8faadc"
												onClick={() =>
													downloadFile(
														x.timelineTreatmentPlanServiceTask.uploadFileUri,
														x.timelineServiceTask.name,
													)
												}
											/>
										</span>
									</div>
								</Collapse>
							</span>
						</div>
					)
				} )}

				{dataTimelineTodo.map( ( x, index ) => {
					return (
						<div className={`tl-item ${x.id ? "" : ""}`}>
							<div className={`tl-dot ${index == 0 ? " b-current" : ""}`}></div>
							<div>
								<header>
									{x.treatmentPlanServiceTask.name.charAt( 0 ).toUpperCase() +
										x.treatmentPlanServiceTask.name.slice( 1 )}
								</header>
							</div>
						</div>
					)
				} )}
			</div>
		</div>
	)
}

export default AppGraph
