import { faCircleDown } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon as FA } from "@fortawesome/react-fontawesome"
import React from "react"
import { Table } from "react-bootstrap"
import ModalImage from "react-modal-image"
import Moment from "react-moment"
import { getFileReadUrlQuery } from "../../../services/CelebMedicalFile.Service"
import { getPatientTreatmentFilesQuery } from "../../../services/CelebTreatmentFiles.Service"
import { useSidebarStore } from "../../../store/sideBarStore"
import {
	sidebarPhotosLibrary as photosLibrary,
	sidebarPhotosLibraryReset as photosLibraryReset,
} from "../../../utils/SidebarphotoFileShowCase"
import { downloadFile } from "../../../utils/utilFunctions"
import { fileResult } from "../../dialogs/replaceOrder-v2/PatientPhotos.tab"
import { CheckGenericPermission } from "../../permissions/CheckPermision"
import { fileReadableName, infoMapperNames } from "../../utlity/GenricFunctions"
import "./sidebar.css"

const Records = () => {
	const sideBarData = useSidebarStore()
	const { data: fileData } = getPatientTreatmentFilesQuery(
		sideBarData?.sideBarPatientData?.PatientData?.id,
	)

	React.useEffect( () => {
		return photosLibraryReset()
	}, [ sideBarData ] )

	const treatmentFiles = fileData.filter( ( file: fileResult ) => file.fileCategory !== "editor_stl" )

	fileData.forEach( ( file: fileResult ) => {
		if ( file.fileCategory.includes( "photo" ) ) {
			const imageUri = getFileReadUrlQuery( file.fileUri )
			photosLibrary[ file.fileSubCategory ] = imageUri.data
		}
	} )
	const photoComposite = photosLibrary[ "patient_photo_composite" ]

	return (
		<main
			style={{
				backgroundColor: "#d9d9d9",
				width: "-webkit-fill-available",
				borderRadius: "10px",
				overflow: "scroll",
				scrollbarWidth: "none",
			}}
			className="p-3"
		>
			{photoComposite ? (
				<CheckGenericPermission genericLabel="Treatment Photo Composite">
					<div className="card" style={{ backgroundColor: "#d9d9d9" }}>
						<ModalImage small={photoComposite} large={photoComposite} className="composite-image" />
					</div>
				</CheckGenericPermission>
			) : (
				<CheckGenericPermission genericLabel="Treatment Photo Individual">
					<div>
						<Table variant="secondary" responsive className="mt-2">
							<tbody>
								<center>
									<tr className="border-0">
										<td>
											<ModalImage
												className="imgGridPlace "
												large={photosLibrary[ "patient_photo_face_side" ]}
												small={photosLibrary[ "patient_photo_face_side" ]}
											/>
										</td>

										<td>
											<ModalImage
												className="imgGridPlace"
												large={photosLibrary[ "patient_photo_face_front" ]}
												small={photosLibrary[ "patient_photo_face_front" ]}
											/>
										</td>
										<td>
											<ModalImage
												className="imgGridPlace"
												large={photosLibrary[ "patient_photo_face_front_teeth" ]}
												small={photosLibrary[ "patient_photo_face_front_teeth" ]}
											/>
										</td>
									</tr>

									<tr className="border-0">
										<td>
											<ModalImage
												className="imgGridPlace"
												large={photosLibrary[ "patient_photo_jaw_upper" ]}
												small={photosLibrary[ "patient_photo_jaw_upper" ]}
											/>
										</td>
										<td>
											<ModalImage
												className=""
												large={photosLibrary[ "" ]}
												small={photosLibrary[ "" ]}
											/>
										</td>
										<td>
											<ModalImage
												className="imgGridPlace"
												large={photosLibrary[ "patient_photo_jaw_right" ]}
												small={photosLibrary[ "patient_photo_jaw_right" ]}
											/>
										</td>
									</tr>
									<tr className="border-0">
										<td>
											<ModalImage
												className="imgGridPlace"
												large={photosLibrary[ "patient_photo_jaw_front" ]}
												small={photosLibrary[ "patient_photo_jaw_front" ]}
											/>
										</td>
										<td>
											<ModalImage
												className="imgGridPlace"
												large={photosLibrary[ "patient_photo_jaw_left" ]}
												small={photosLibrary[ "patient_photo_jaw_left" ]}
											/>
										</td>
										<td>
											<ModalImage
												className="imgGridPlace"
												large={photosLibrary[ "patient_photo_jaw_lower" ]}
												small={photosLibrary[ "patient_photo_jaw_lower" ]}
											/>
										</td>
									</tr>
								</center>
							</tbody>
						</Table>
					</div>
				</CheckGenericPermission>
			)}
			<hr />
			<text className="sidebar-subject">Files:</text>
			<br />
			<div>
				{treatmentFiles.map( ( file: fileResult, index: number ) => {
					if ( !file.fileCategory.includes( "photo" ) ) {
						return (
							<CheckGenericPermission genericLabel="Treatment Files">
								<div
									className="file-item mb-2 p-2 border rounded"
									key={index}
									onClick={() => downloadFile( file.fileUri, file.originalFileName )}
								>
									<span className="icon-container">
										<FA icon={faCircleDown} style={{ fontSize: "35px", color: "#8faadc" }} />
									</span>
									<span className="flex-grow-1">
										<div className="file-name">{fileReadableName( file.fileSubCategory )}</div>
										<div className="doctor-name">
											{infoMapperNames( sideBarData.sideBarPatientData.PatientData.doctor, [ "id" ] )}
										</div>
									</span>
									<span className="date-container text-end">
										<div className="date-text">
											<Moment format="MM/DD/YYYY hh:mma" local>
												{file.updatedAt}
											</Moment>
										</div>
									</span>
								</div>
							</CheckGenericPermission>
						)
					}
				} )}
			</div>
		</main>
	)
}

export default Records
