import React from "react"
import { useSearchParams } from "react-router-dom"
import CompletedTable from "../../../../components/dashboard/CompletedTable"
import TasksTable from "../../../../components/dashboard/TasksTable"

const landingPage = () => {
	return (
		<React.Suspense>
			<LandingPageImpl />
		</React.Suspense>
	)
}

const LandingPageImpl = () => {
	return (
		<React.Suspense>
			<main>
				<RenderLandingPage />
			</main>
		</React.Suspense>
	)
}

const RenderLandingPage = () => {
	const [ searchParams, setSearchParams ] = useSearchParams()
	const status = searchParams.get( "status" )
	switch ( status ) {
		case "completed":
		case "paused":
		case "archived":
			return <CompletedTable />
		default:
			return <TasksTable />
	}
}

export default landingPage
