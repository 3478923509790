import { faPencil, faTrash } from "@fortawesome/free-solid-svg-icons"
import Pagination from "rc-pagination"
import "rc-pagination/assets/index.css"
import React, { Suspense, useRef, useState } from "react"
import { Badge, Button, Col, Modal, Nav, Navbar, Row, Table } from "react-bootstrap"
import LoadingOverlay from "../../../assets/styles/components/loader/loadingOverlay"
import { constants } from "../../../services/CelebApiConstants"
import {
	useDeleteUserMutation,
	useListUsersQuery,
	useResetPasswordMutation,
} from "../../../services/CelebUser.Service"
import { useAccountsDialogStore } from "../../../store/accountsDialogStore"
import { useLoadingStore } from "../../../store/userSessionStore"
import SearchInput from "../../form/SearchInput"
import { CheckGenericPermission } from "../../permissions/CheckPermision"
import { ToastPopup } from "../../toast/Toast"
import { EditTrash } from "../../utlity/EditTrash"
import { ResetPasswordDialog } from "../../utlity/ResetPassword"
import { AccountDeleteDialog, DeleteDialog } from "../DeleteDialog"
import AccountsAddDialog from "./AccountsAdd.dialog"
import AccountEditDialog from "./AccountsEdit.dialog"
import { AccountTypeTabList } from "./AccountTypeTabList.tab"

export const AccountsListDialog = () => {
	const listOpen = useAccountsDialogStore( ( state ) => state.listOpen )
	const setListOpen = useAccountsDialogStore( ( state ) => state.setListOpen )
	return (
		<Suspense>
			<Modal show={listOpen} onHide={() => setListOpen( false )} size="xl" backdrop="static">
				<AccountsListDialogImpl />
			</Modal>
		</Suspense>
	)
}

const AccountsListDialogImpl = () => {
	const [ currentPage, setCurrentPage ] = useState( 1 )
	const [ selectedTab, setSelectedTab ] = useState<number>( 0 )
	const [ query, setQuery ] = useState( "" )
	const { isLoading } = useLoadingStore()
	const pageOffset = ( currentPage - 1 ) * constants.PAGE_COUNT
	const {
		data: { data: users, total },
	} = useListUsersQuery( { pageOffset, query: query, limit: constants.PAGE_COUNT } )

	const { mutateAsync: deleteUserAsync, isPending } = useDeleteUserMutation()
	const { mutateAsync: useResetPasswordLink } = useResetPasswordMutation()

	const showAddDialog = () => {
		useAccountsDialogStore.getState().setCreateOpen( true )
	}

	const onTabSelected = ( tabIndex: number ) => {
		setSelectedTab( tabIndex )
	}
	const DeleteDialogref = useRef<AccountDeleteDialog>( null )
	const onDelete = async ( id: string ) => {
		await deleteUserAsync( { id } )
	}

	const newPasswordDialog = ( userData: any ) => {
		useAccountsDialogStore.getState().setResetPasswordModal( {
			show: true,
			userId: userData.id,
			name: userData.firstName + " " + userData.lastName,
		} )
	}

	const handlePagination = ( pageNumber: number ) => {
		setCurrentPage( pageNumber )
	}
	const onQueryChange = ( q: string ) => {
		setQuery( q )
		setCurrentPage( 1 )
	}

	return (
		<React.Fragment>
			<Navbar style={{ backgroundColor: "#d9d9d9" }} as={Modal.Header} closeButton>
				<Nav>
					<Nav.Link
						className="nav-link-opposite mt-1"
						active={selectedTab == 0}
						onClick={() => {
							onTabSelected( 0 )
						}}
					>
						Accounts
					</Nav.Link>
					<Nav.Link
						className="nav-link-opposite mt-1"
						active={selectedTab == 1}
						onClick={() => {
							onTabSelected( 1 )
						}}
					>
						New Account Type
					</Nav.Link>
				</Nav>
			</Navbar>

			<Modal.Body>
				{isLoading && <LoadingOverlay />}
				<div
					className={
						"" +
						( selectedTab !== 0 ? "d-none d-md-none d-lg-none" : "d-flex justify-content-between" )
					}
				>
					<span className="d-flex">
						<SearchInput callback={onQueryChange} />
						<CheckGenericPermission genericLabel={"Accounts List"}>
							<Badge bg="secondary" className="mx-2 d-grid align-content-center">
								{total} accounts
							</Badge>
						</CheckGenericPermission>
					</span>
					<CheckGenericPermission genericLabel={"Accounts Create"}>
						<Button onClick={showAddDialog}>Create New Account</Button>
					</CheckGenericPermission>
				</div>
				<CheckGenericPermission genericLabel={"Accounts List"}>
					<Row className={selectedTab !== 0 ? "d-none d-md-none d-lg-none" : ""}>
						<Col>
							<Table bordered responsive striped className="mt-4" hover>
								<thead>
									<tr>
										<th>Acc ID</th>
										<th>First Name</th>
										<th>Last Name</th>
										<th>Email</th>
										<th>Phone</th>
										<th>Status</th>
										<th>Role</th>
										<th>Parent account</th>
										<th>Actions</th>
									</tr>
								</thead>
								<tbody>
									{users.map( ( user: any ) => {
										return (
											<tr key={user.id}>
												<td>{user.entityId}</td>
												<td>{user.firstName}</td>
												<td>{user.lastName}</td>
												<td>{user.email}</td>
												<td>{user.contactNumber}</td>
												<td>{user.status ? "Active" : " Inactive"}</td>
												<td>{user.role.name}</td>
												<td>
													{user.parentAccountId
														? user.parentAccount.firstName + " " + user.parentAccount.lastName
														: "-"}
												</td>
												<td className="text-right">
													<CheckGenericPermission genericLabel={"Accounts Edit"}>
														<EditTrash
															editIcon={{ icon: faPencil, color: "#8faadc" }}
															trashIcon={{ icon: faTrash, color: "#717273" }}
															deleteCallBack={() => DeleteDialogref.current?.showDialog( user )}
															editCallback={() =>
																useAccountsDialogStore.getState().setEditOpen( user.id )
															}
															dropdownShow={true}
															dropdownOptions={[
																{
																	label: "Send Password Link",
																	cb: ( e ) => useResetPasswordLink( { id: user.id } ),
																},
																{
																	label: "Reset Password",
																	cb: ( e ) => newPasswordDialog( user ),
																},
															]}
														/>
													</CheckGenericPermission>
												</td>
											</tr>
										)
									} )}
								</tbody>
							</Table>
						</Col>
						<Pagination
							total={total}
							pageSize={constants.PAGE_COUNT}
							onChange={handlePagination}
							align={"center"}
						/>
					</Row>
				</CheckGenericPermission>
				{selectedTab === 0 && users.length === 0 ? (
					<Row>
						<Col>No users found.</Col>
					</Row>
				) : null}
				<Row className={selectedTab !== 1 ? "d-none d-md-none d-lg-none" : ""}>
					<Suspense>
						<AccountTypeTabList />
					</Suspense>
				</Row>
			</Modal.Body>
			<ResetPasswordDialog />
			<DeleteDialog
				ref={DeleteDialogref}
				onDeleteOkCallback={onDelete}
				deletingEntityName="firstName"
			/>
			<AccountsAddDialog />
			<AccountEditDialog />
			<ToastPopup />
		</React.Fragment>
	)
}
