import React from "react"
import { Badge, ButtonGroup, Dropdown, Nav, Navbar, NavDropdown } from "react-bootstrap"
import { Link, Params, useNavigate, useParams, useSearchParams } from "react-router-dom"
import Select from "react-select"
import { useGetAssignedDoctors } from "../../services/CelebSalesRepresentative.service"
import {
	CategoriesResponseType,
	CategoriesType,
	getServiceTreatmentCount,
	useListAllServicesQuery,
} from "../../services/CelebService.Service"
import { usePatientDialogStore } from "../../store/patientDailogStore"
import { useTaskCompletionDialogStore } from "../../store/taskCompletionDialogStore"
import { TaskCategory } from "../../utils/appConstants"
import SearchInput from "../form/SearchInput"
import {
	CheckAdmin,
	CheckDoctor,
	CheckGenericPermission,
	CheckOperator,
	CheckSales,
} from "../permissions/CheckPermision"

const SubNavBar = () => {
	const paramsData: Readonly<Params<string>> = useParams()
	const [ searchParams, setSearchParams ] = useSearchParams()
	const taskCompletionStore = useTaskCompletionDialogStore()
	const patientStore = usePatientDialogStore()
	const status = searchParams.get( "status" )
	const { data: categoriesData } = useListAllServicesQuery()
	const { data: serviceTreatmentCounts } = getServiceTreatmentCount( paramsData?.id, status + "" )
	const { data: doctorList } = useGetAssignedDoctors()
	const navigate = useNavigate()
	const category = searchParams.get( "provide" )
	const categoryconsume = searchParams.get( "consume" )
	const doctorId = searchParams.get( "doctorId" )
	const providerCategories =
		categoriesData?.services &&
		categoriesData?.services.find(
			( categorys: CategoriesResponseType ) => paramsData.id === categorys.id,
		)
	React.useEffect( () => {
		doctorId == "undefined" && searchParams.delete( "doctorId" )
		return navigate( "?status=active" )
	}, [] )
	const doctorListOptions = doctorList.data.map( ( person ) => ( {
		value: person?.saleClientDoctor?.id,
		label: "Dr." + person?.saleClientDoctor?.firstName + " " + person?.saleClientDoctor?.lastName,
	} ) )

	const openCompleteTask = () => {
		taskCompletionStore.landingPageTasks.length >= 1
			? taskCompletionStore.setDialogOpen( true )
			: null
	}

	const setSearch = ( search: string ) => {
		const newParams = new URLSearchParams( searchParams )
		newParams.set( "searchPatient", search )
		setSearchParams( newParams )
		if ( search.length == 0 ) {
			const newParameter = new URLSearchParams( window.location.search )
			newParameter.delete( "searchPatient" )
			const newUrl = `${window.location.pathname}?${newParameter.toString()}`
			return navigate( newUrl )
		}
	}

	const searchDoctorSales = ( doctorId: string ) => {
		if ( !doctorId ) return
		const newParams = new URLSearchParams( searchParams )
		newParams.set( "doctorId", doctorId )
		setSearchParams( newParams )
	}
	return (
		<nav style={{ backgroundColor: "#d9d9d9" }}>
			<div className="d-flex justify-content-between align-items-center p-2">
				<div className="d-flex gap-2">
					<SearchInput callback={( search: string ) => setSearch( search )} />
					<CheckSales>
						<div style={{ width: "12rem" }}>
							<Select
								options={doctorListOptions}
								isSearchable={true}
								defaultValue={doctorListOptions.find( ( option ) => option.value === doctorId )}
								styles={{
									control: ( baseStyles, state ) => ( {
										...baseStyles,
										borderRadius: "7px",
									} ),
								}}
								placeholder="Select Doctor"
								isClearable={true}
								onChange={( e ) => searchDoctorSales( e?.value + "" )}
							/>
						</div>
					</CheckSales>
				</div>
				<span className="text-end">
					<CheckGenericPermission genericLabel={"Task Completion"}>
						<a href="#TaskCompletion" className="btn btn-primary me-2" onClick={openCompleteTask}>
							Complete task
						</a>
					</CheckGenericPermission>
					<CheckGenericPermission genericLabel={"Patient Create"}>
						<a
							href="#PatientInfo"
							className="btn btn-primary me-2"
							onClick={() => {
								patientStore.setCreateOpen( true )
							}}
						>
							Create New Patient
						</a>
					</CheckGenericPermission>
				</span>
			</div>
			<Navbar expand="lg" className="p-0">
				<Navbar.Toggle aria-controls="basic-navbar-nav" />
				<Navbar.Collapse id="basic-navbar-nav">
					<div className="w-100 text-start" style={{ backgroundColor: "#d9d9d9" }}>
						<div className="w-100">
							<Nav variant="tabs" className="w-100 m-0 p-0" style={{ border: "none" }}>
								<CheckAdmin>
									<Dropdown
										title="All"
										id="basic-nav-dropdown"
										as={ButtonGroup}
										style={{
											border: "none",
											backgroundColor: status === "active" || category ? "white" : "transparent",
											borderRadius: status === "active" || category ? "10px 10px 0 0" : "0",
										}}
									>
										<Nav.Link
											active={status == "active"}
											as={Link}
											to={"?status=active"}
											className="border-0"
										>
											All{" "}
											<Badge
												bg="light"
												style={{ color: "grey" }}
												pill
												className="countPill text-end"
											>
												{serviceTreatmentCounts?.count?.active}
											</Badge>
										</Nav.Link>
										<Dropdown.Toggle split variant="Secondary" id="dropdown-split-basic" />
										<Dropdown.Menu>
											{providerCategories?.categories.map( ( cat: CategoriesType ) => {
												if ( cat.type == TaskCategory.OTHERS ) return null
												return (
													<NavDropdown.Item
														className="btn border-0"
														active={category == cat.id}
														as={Link}
														to={"?status=active&" + cat.type + "=" + cat.id}
													>
														{cat.name}
													</NavDropdown.Item>
												)
											} )}
										</Dropdown.Menu>
									</Dropdown>
								</CheckAdmin>

								<CheckOperator>
									<Dropdown
										title="All"
										id="basic-nav-dropdown"
										as={ButtonGroup}
										className="border-0"
										style={{
											border: "none",
											backgroundColor: status === "active" || category ? "white" : "transparent",
											borderRadius: status === "active" || category ? "10px 10px 0 0" : "0",
										}}
									>
										<Nav.Link
											active={status == "active"}
											as={Link}
											to={"?status=active"}
											className="border-0"
										>
											Action Needed{" "}
										</Nav.Link>
										<Dropdown.Toggle split variant="Secondary" id="dropdown-split-basic" />
										<Dropdown.Menu>
											{providerCategories?.categories.map( ( cat: CategoriesType ) => {
												if ( cat.type == TaskCategory.OTHERS ) return null
												return (
													<NavDropdown.Item
														className="btn border-0"
														as={Link}
														active={category == cat.id}
														to={"?status=active&" + cat.type + "=" + cat.id}
													>
														{cat.name}
													</NavDropdown.Item>
												)
											} )}
										</Dropdown.Menu>
									</Dropdown>
								</CheckOperator>

								<CheckDoctor>
									<Nav.Link
										className="nav-link"
										active={status == "active"}
										as={Link}
										to={`?status=active`}
									>
										Action Needed
									</Nav.Link>
								</CheckDoctor>

								<CheckSales>
									<Nav.Link
										className="nav-link"
										active={status == "active"}
										as={Link}
										to={`?status=active${doctorId != "undefined" && doctorId ? "&doctorId=" + doctorId : ""}`}
									>
										Action Needed
									</Nav.Link>
								</CheckSales>

								<CheckOperator>
									<Nav.Link
										className="nav-link"
										active={status == "AwaitingDoctor"}
										eventKey={"?status=AwaitingDoctor"}
										as={Link}
										to={`?status=AwaitingDoctor`}
									>
										Awaiting Doctor
									</Nav.Link>
								</CheckOperator>

								<CheckSales>
									{providerCategories?.categories.map( ( cat: CategoriesType ) => {
										if ( cat.type !== "consume" ) return null
										return (
											<Nav.Link
												className="nav-link"
												active={categoryconsume == cat.id}
												eventKey={"?" + cat.type + "=" + cat.id}
												as={Link}
												to={`?${cat.type}=${cat.id}${doctorId != "undefined" && doctorId ? "&doctorId=" + doctorId : ""}`}
												key={cat.id}
											>
												{cat.name}
											</Nav.Link>
										)
									} )}
								</CheckSales>

								<CheckDoctor>
									{providerCategories?.categories.map( ( cat: CategoriesType ) => {
										if ( cat.type !== "consume" ) return null
										return (
											<Nav.Link
												className="nav-link"
												active={categoryconsume == cat.id}
												eventKey={"?" + cat.type + "=" + cat.id}
												as={Link}
												to={`?${cat.type}=${cat.id}`}
												key={cat.id}
											>
												{cat.name}
											</Nav.Link>
										)
									} )}
								</CheckDoctor>

								<Nav.Link
									className="nav-link"
									active={status == "paused"}
									eventKey={"?status=paused"}
									as={Link}
									to={`?status=paused${doctorId != "undefined" && doctorId ? "&doctorId=" + doctorId : ""}`}
								>
									Pause{" "}
									<CheckAdmin>
										<Badge bg="light" style={{ color: "grey" }} pill className="countPill text-end">
											{serviceTreatmentCounts?.count?.paused}
										</Badge>
									</CheckAdmin>
								</Nav.Link>

								<Nav.Link
									className="nav-link"
									active={status == "archived"}
									eventKey={"?status=archived"}
									as={Link}
									to={`?status=archived${doctorId != "undefined" && doctorId ? "&doctorId=" + doctorId : ""}`}
								>
									Archived{" "}
									<CheckAdmin>
										<Badge bg="light" style={{ color: "grey" }} pill className="countPill text-end">
											{serviceTreatmentCounts?.count?.archived}
										</Badge>
									</CheckAdmin>
								</Nav.Link>

								<Nav.Link
									className="nav-link"
									active={status == "completed"}
									eventKey={"?status=completed"}
									as={Link}
									to={`?status=completed${doctorId != "undefined" && doctorId ? "&doctorId=" + doctorId : ""}`}
								>
									Shipped{" "}
									<CheckAdmin>
										<Badge
											bg="light"
											style={{ color: "grey", background: "red !important" }}
											pill
											className="countPill text-end"
										>
											{serviceTreatmentCounts?.count?.completed}
										</Badge>
									</CheckAdmin>
								</Nav.Link>
							</Nav>
						</div>
					</div>
				</Navbar.Collapse>
			</Navbar>
		</nav>
	)
}

export default SubNavBar
