import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { zodResolver } from "@hookform/resolvers/zod"
import { useState } from "react"
import { Card, Container, Form, InputGroup } from "react-bootstrap"
import { FormProvider, useForm, useFormContext } from "react-hook-form"
import { useNavigate, useSearchParams } from "react-router-dom"
import {
	ResetPasswordRequest,
	ResetPasswordSchema,
	useForgotResetMuation,
} from "../../services/CelebAuth.Service"
import "./resetPassword.css"
import { userSessionStore } from "../../store/userSessionStore"

const ResetPassword = () => {
	const methods = useForm<ResetPasswordRequest>( {
		resolver: zodResolver( ResetPasswordSchema ),
		mode: "onSubmit",
	} )

	return (
		<FormProvider {...methods}>
			<ResetPasswordImp />
		</FormProvider>
	)
}

const ResetPasswordImp = () => {
	const {
		register,
		handleSubmit,
		getValues,
		reset,
		formState: { errors },
	} = useFormContext<ResetPasswordRequest>()
	const navigate = useNavigate()
	const [ showPassword, setShowPassword ] = useState( false )
	const [ showConfirmPassword, setShowConfirmPassword ] = useState( false )
	const [ searchParams ] = useSearchParams()
	const resetLink = searchParams.get( "resetlink" )
	const { mutateAsync: resetPassword } = useForgotResetMuation()
	const { setShowToastJson } = userSessionStore()
	const onSubmit = async ( data: ResetPasswordRequest ) => {
		try {
			const result = await resetPassword( {
				password: data.confirmPassword,
				token: resetLink || "",
			} )

			setShowToastJson( {
				message: "Password reset success",
				status: "Success",
				visible: true,
			} )
			setTimeout(()=>{window.location.reload()},3000)
		} catch ( error ) {
			console.error( error )
		}
	}

	const onCancel = () => {
		reset()
		navigate( "/Login" )
	}

	return (
		<Container
			style={{
				width: "100%",
				height: "98dvh",
				display: "grid",
				justifyContent: "center",
				alignItems: "center",
				alignContent: "center",
			}}
		>
			<Card
				className="loginContainer"
				style={{ width: "25rem", minHeight: "17.5rem", marginBottom: 0 }}
			>
				<Card.Header className="d-flex text-center">
					<span className="d-flex w-100 text-center justify-content-center gap-2">
						<img
							src="./logo.png"
							style={{ height: "30px", width: "30px", mixBlendMode: "darken" }}
						/>
						<h4 className="text-center" style={{ display: "grid", alignItems: "end" }}>
							Celebrace Password Reset
						</h4>
					</span>
				</Card.Header>
				<Form className="card-body mt-4" onSubmit={handleSubmit( onSubmit, ( e ) => console.error( e ) )}>
					{errors.root?.message && (
						<div className="text-danger text-center mb-3">{errors.root.message}</div>
					)}
					<InputGroup className="mb-3 position-relative">
						<Form.Control
							{...register( "password", { required: "Password is required" } )}
							type={showPassword ? "text" : "password"}
							isInvalid={!!errors.password}
							placeholder="New password"
							autoComplete="current-password"
						/>
						<InputGroup.Text
							onClick={() => setShowPassword( !showPassword )}
							style={{ cursor: "pointer" }}
						>
							<FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
						</InputGroup.Text>
						<div className="tooltip-message">
							Password must be at least 6 characters, include at least one uppercase letter, one
							lowercase letter, and one digit.
						</div>
					</InputGroup>
					{errors.password && (
						<div className="invalid-feedback" style={{ display: "block" }}>
							{errors.password.message}
						</div>
					)}
					<InputGroup className="mb-3">
						<Form.Control
							{...register( "confirmPassword", {
								required: "Confirm password is required",
								validate: ( value ) => {
									const password = getValues( "password" )
									return value === password || "Passwords do not match"
								},
							} )}
							type={showConfirmPassword ? "text" : "password"}
							isInvalid={!!errors.confirmPassword}
							placeholder="Confirm password"
							autoComplete="current-password"
						/>
						<InputGroup.Text style={{ cursor: "pointer" }}>
							<FontAwesomeIcon
								onClick={() => setShowConfirmPassword( !showConfirmPassword )}
								icon={showConfirmPassword ? faEyeSlash : faEye}
							/>
						</InputGroup.Text>
						<div className="tooltip-message">Ensure this matches the new password.</div>
					</InputGroup>
					{errors.confirmPassword && (
						<div className="invalid-feedback" style={{ display: "block" }}>
							{errors.confirmPassword.message}
						</div>
					)}
					<div className="text-end mt-2">
						<button className="btn btn-secondary me-2" type="button" onClick={onCancel}>
							Cancel
						</button>
						<button className="btn btn-primary" type="submit">
							Submit
						</button>
					</div>
				</Form>
			</Card>
		</Container>
	)
}

export default ResetPassword
