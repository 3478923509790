import { Card } from "react-bootstrap"
import { useSearchParams } from "react-router-dom"
import ToothModel from "../../../assets/components/toothModel"
import { getPatientByIdQuery } from "../../../services/CelebTreatment.Service"
import { useSidebarStore } from "../../../store/sideBarStore"
import "./sidebar.css"

const ReplaceOrderDetails = () => {
	const [ searchParams, setSearchParams ] = useSearchParams()
	const sidebar = useSidebarStore().sideBarPatientData.PatientData
	const patientId = searchParams.get( "PatientId" )
	const { data: toothData } = getPatientByIdQuery( patientId )

	return (
		<div
			style={{ height: "100%", backgroundColor: "#d9d9d9", width: "100%", borderRadius: "10px" }}
		>
			<Card
				className="m-1"
				style={{ backgroundColor: "#d9d9d9", border: "none", borderRadius: "10px" }}
			>
				<Card.Header className="text-center">Replace Order</Card.Header>
				<div className="sideBarPatientInfo">
					<div>
						<span className="label">Wires :</span>{" "}
					</div>
					<span>
						<span className="sub-label">Upper Wire :</span>
						<span className="label-data-wire">
							{toothData.reaplceOrderDetails &&
								toothData.reaplceOrderDetails.upperWires.map( ( x: string ) => <text>{x}</text> )}
						</span>
					</span>
					<span>
						<span className="sub-label">Lower Wire :</span>
						<span className="label-data">
							{toothData.reaplceOrderDetails &&
								toothData.reaplceOrderDetails.lowerWires.map( ( x: string ) => <text>{x}</text> )}
						</span>
					</span>
					<div>
						<span className="label">Replace Instructions:</span>{" "}
						<span className="label-data">{toothData?.reaplceOrderDetails?.note || "N/A"}</span>
					</div>

					<div className="mt-3">
						<span className="label">Bonding Teeths :</span>{" "}
						<ToothModel usedFor={"teethReplacement"} />
					</div>
				</div>
			</Card>
		</div>
	)
}

export default ReplaceOrderDetails
