import { faCloudUpload } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import JSZip from "jszip"
import React, { useEffect, useState } from "react"
import { Col, Form, Row } from "react-bootstrap"
import { useDropzone } from "react-dropzone"
import { getPatientTreatmentFilesQuery } from "../../../services/CelebTreatmentFiles.Service"
import { usePatientDialogStore } from "../../../store/patientDailogStore"
import "./Patient.css"

const PatientCbctTab = () => {
	const { typesEditOpen, setCbctDocument } = usePatientDialogStore()
	const { data: defaultTreatmentFiles } = getPatientTreatmentFilesQuery( typesEditOpen )
	const [ cbct, setCbct ] = useState<"noCbct" | "cbct">(
		defaultTreatmentFiles.length <= 0 ? "noCbct" : "cbct",
	)
	return (
		<React.Fragment>
			<div className="cbct-radio-container">
				<Form.Check
					reverse
					defaultChecked={cbct == "noCbct" && true}
					type="radio"
					label="No CBCT"
					name="cbctSelection"
					onChange={() => {
						setCbct( "noCbct" )
						setCbctDocument( "patient_cbct", null )
					}}
				/>
				<Form.Check
					reverse
					type="radio"
					defaultChecked={cbct == "cbct" && true}
					label="Upload CBCT"
					name="cbctSelection"
					onChange={() => setCbct( "cbct" )}
				/>
			</div>
			{cbct == "cbct" && <CbctUpload />}
		</React.Fragment>
	)
}

const CbctUpload = () => {
	const { setCbctDocument, typesEditOpen } = usePatientDialogStore()
	const [ filesList, setFilesList ] = useState<File[]>( [] )
	const [uploadType,setUploadType]=useState<boolean>()
	const { data: defaultTreatmentFiles } = getPatientTreatmentFilesQuery( typesEditOpen )
	const { getRootProps, getInputProps, open } = useDropzone( {
		noClick: true,
		noKeyboard: true,
		onDrop: ( acceptedFiles ) => {
			setFilesList( acceptedFiles )
		},
	} )

	const fileZipSaver = async () => {
		if ( filesList.length === 0 ) return
		const newZip = new JSZip()
		filesList.forEach( ( file ) => {
			newZip.file( file.name, file )
		} )
		try {
			const zipBlob = await newZip.generateAsync( { type: "blob" } )
			const zipFile = new File( [ zipBlob ], "cbct_files.zip", {
				type: "application/zip",
			} )
			setCbctDocument( "patient_cbct", zipFile )
		} catch ( error ) {
			console.error( "Error generating zip file:", error )
		}
	}

	useEffect( () => {
		fileZipSaver()
	}, [ filesList ] )

	const files = filesList.map( ( file ) => <li key={file.name}>{file.name}</li> )

	const checkFiles =
		files.length > 0
			? files
			: defaultTreatmentFiles.map(
					( file: {
						originalFileName: string
						fileUri: string
						fileSubCategory: string
						fileCategory: string
					} ) => {
						if ( file.fileCategory === "patient_cbct" ) {
							return <li key={file.originalFileName}>{file.originalFileName}</li>
						}
					},
				)

	return (
		<Form>
			<Row className="my-4">
				<Col>
					<div className="cbct-container">
					<div {...getRootProps( { className: "cbct-dropzone" } )}>
							<input {...getInputProps( { dir: uploadType?"":undefined, webkitdirectory: uploadType?"true":undefined } )} />
							<FontAwesomeIcon icon={faCloudUpload} className="cbct-icon" />
							<p className="cbct-message">Drag 'n' drop some files here</p>
							<button className="btn btn-primary cbct-button mx-2" type="button"  onClickCapture={()=>setUploadType(true)} onClick={()=>{setUploadType(true);open()}}>
								Select Folder
							</button>
							<button className="btn btn-primary cbct-button" type="button" onClickCapture={()=>setUploadType(false)} onClick={()=>{setUploadType(false);open()}}>
								Select File
							</button>
						</div>
						<aside>
							<ul className="cbct-file-list">{checkFiles}</ul>
						</aside>
					</div>
				</Col>
			</Row>
		</Form>
	)
}

export default PatientCbctTab
